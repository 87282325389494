<template>
  <el-dialog
    append-to-body
    width="400px"
    title="修改供应商"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
    <el-form
      class="staff-from"
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='100px'
      label-position='top'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item label="供应商/采购员" prop="supplier_id">
        <el-select v-model="form.data.supplier_id" filterable @focus="getSupplier" style="width:100%;margin:10px 0;">
          <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'SupplierEdit',
    props: {
      setDataApi: { required: true, type:String, default: '' }, // 修改供应商/采购员保存接口
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        loading: false,
        detail_ids: '',
        form: {
          loading: false,
          data: {
            id: '',
            supplier_id: '',
          },
          rules: {
            supplier_id: [{ required: true, message:'请选择供应商', trigger: 'change'}],
          }
        },
        supplierArr: [], // 供应商/采购员
      }
    },
    methods: {
      getDetail(row,type) {
        this.getSupplier();
        if(type == 1) { // type:1单条修改供应商/采购员，处理反显值，批量则不需要反显
          this.form.data.id = row.id; // 采购单id
          let str = this.supplierArr.some(v => v.id == row.supplier_id); // 检测数组中的元素是否满足指定条件，如果没有满足条件的元素返回false
          this.form.data.supplier_id = (row.supplier_id == 0 || str == false) ? '' : row.supplier_id; // 供应商/采购员反显
        } else {
          this.detail_ids = row.multipleSelection.map(v => { return v.deltil_id }).join("|"); //明细ID 多个竖线隔开
          this.form.data.supplier_id = ''; //供应商、采购员ID
          this.form.data.id = row.id; // 采购单id
        }
      },
      reset(done) {
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 供应商/采购员(status:1启用中的供应商)
      getSupplier() {
        this.$http.get('/admin/supplier/allList',{params:{status:1,page:1,count:10000}}).then(res => {
          if(res.code == 1) {
            this.supplierArr = res.data.list;
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if(valid) {
            let _params = {
              id: this.form.data.id, // id
              detail_ids: this.detail_ids, //明细ID 多个竖线隔开
              supplier_id: this.form.data.supplier_id, // 供应商/采购员id
            }
            this.form.loading = true;
            this.$http.post(this.setDataApi, _params).then(res => {
              if(res.code == 1) {
                this.$message.success("操作成功！");
                this.toggle(false);
                this.$emit("refresh");
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
